const projects = [
  {
    title: 'Nice Turn Signal',
    description: 'Nobody ever means this.',
    thumbnail: '/images/niceturnsignal.avif',
    tags: ['Javascript', 'HTML', 'CSS'],
    link: 'https://wow.niceturnsignal.com/'
  },
  {
    title: 'Canadian Standoff',
    description: 'When two or more people at a door cannot decide who should enter or exit first.',
    thumbnail: '/images/canadianstandoff.avif',
    tags: ['Javascript', 'HTML', 'CSS'],
    link: 'https://canadianstandoff.com/'
  }
];
export default projects;