/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Mail, Github, Linkedin, ExternalLink, Sun, Moon } from 'lucide-react';
import projects from './components/Projects';

const PersonalPage = () => {
  const [isDark, setIsDark] = useState(true);

  const socialLinks = [
    { icon: Github, label: 'GitHub', href: 'https://github.com/richmcninch' },
    { icon: Linkedin, label: 'LinkedIn', href: 'https://www.linkedin.com/in/richmcninch/' },
  ];

  return (
    <div className={`min-h-screen flex flex-col items-center justify-center transition-colors duration-300 ${
      isDark ? 'bg-gray-900' : 'bg-gray-50'
    }`}>
      <div className={`max-w-4xl w-full rounded-lg shadow-lg p-8 m-4 transition-colors duration-300 ${
        isDark ? 'bg-gray-800' : 'bg-white'
      }`}>
        {/* Theme Toggle */}
        <div className="flex justify-end mb-4">
          <button
            type="button"
            onClick={() => setIsDark(!isDark)}
            className={`p-2 rounded-lg transition-colors duration-200 ${
              isDark ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'
            }`}
            aria-label="Toggle theme"
          >
            {isDark ? (
              <Sun className="w-5 h-5 text-yellow-400" />
            ) : (
              <Moon className="w-5 h-5 text-gray-600" />
            )}
          </button>
        </div>

        <h1 className={`text-4xl font-bold mb-6 text-center transition-colors duration-300 ${
          isDark ? 'text-white' : 'text-gray-800'
        }`}>
          Rich McNinch
        </h1>
        
        <p className={`text-lg mb-8 text-center transition-colors duration-300 ${
          isDark ? 'text-gray-300' : 'text-gray-600'
        }`}>
          Keep This Coupon
        </p>
        
        {/* Contact and Social Links */}
        <div className="flex flex-wrap items-center justify-center gap-6 mb-12">
          <a 
            href="mailto:rich@richmcninch.com"
            className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors duration-200 font-medium"
          >
            <Mail className="w-5 h-5" />
            rich@richmcninch.com
          </a>
          
          <div className="flex gap-4">
            {socialLinks.map((social) => (
              <a
                key={social.label}
                href={social.href}
                className={`transition-colors duration-200 ${
                  isDark ? 'text-gray-300 hover:text-blue-400' : 'text-gray-600 hover:text-blue-500'
                }`}
                aria-label={social.label}
              >
                <social.icon className="w-6 h-6" />
              </a>
            ))}
          </div>
        </div>

        {/* Projects Grid */}
        <div>
          {projects.map(project => (
            <div key={project.id}>
              <h2>{project.title}</h2>
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <ExternalLink /> Visit Project
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalPage;